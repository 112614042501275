<template>
    <div class="my_visits">
        <div class="list">
            <div class="item" v-for="item in 11" :key="item">
                <img src="../assets/img/touxiang.png" alt="">
                <div class="info">
                    <div class="name">
                        <p>
                            <span>黄西饼</span>
                            <span>2020-11-01</span>
                        </p>
                        <p>待回复</p>
                    </div>
                    <p class="type">
                        <span>图文咨询</span>
                        <span>呼吸科</span>
                    </p>
                    <div class="describe">1个月前跑步时，扭伤了，应该用什么药比较好水电费老师看到福克斯的</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>
.my_visits {
  background: #f8f8f8;
  min-height: calc(100% - 0.2rem);
  padding-top: 0.2rem;
  .list {
    padding: 0.16rem 0.22rem;
    width: calc(100% - 0.44rem);
    .item {
      display: flex;
      align-items: center;
      padding: 0.16rem 0.24rem;
      width: calc(100% - 0.48rem);
      background: #fff;
      border-radius: 2px;
      margin-bottom: 0.2rem;
      img {
        width: 1.22rem;
        height: 1.22rem;
        border-radius: 50%;
        margin-right: 0.2rem;
      }
      .info {
        width: calc(100% - 1.42rem);
        .name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.1rem;
          p:nth-child(1) {
            span:nth-child(1) {
              font-size: 0.3rem;
              color: #333;
              margin-right: 0.32rem;
            }
            span:nth-child(2) {
              font-size: 0.2rem;
              color: #999;
            }
          }
          p:nth-child(2) {
            font-size: 0.24rem;
            color: #ffc025;
          }
        }
        .type {
          margin-bottom: 0.16rem;
          font-size: 0.26rem;
          color: #333;
          span:nth-child(1) {
            margin-right: 0.1rem;
          }
        }
        .describe {
          font-size: 24rpx;
          color: #999999;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>


